import React from "react";
import {Trans, useTranslation} from 'gatsby-plugin-react-i18next';
import Layout from "../../../components/layout";
import Seo from "../../../components/seo";
import {graphql} from 'gatsby';
import {Link} from "gatsby-plugin-react-i18next";
import BookConsultation from "../../../components/pages/BookConsultation";
import {StaticImage} from "gatsby-plugin-image";

import aspire from '../../../images/mobile-access/aspire.png';
import curve from '../../../images/mobile-access/curve.png';
import versa from '../../../images/mobile-access/versa.png';
import orbit from '../../../images/mobile-access/orbit.png';
import pivot from '../../../images/mobile-access/pivot.png';


const IndexPage = () => {
  const {t} = useTranslation();


  return (
      <Layout>
        <Seo
            title={t('mobile_access_title')}
            description={t('des_mobile_access')}
        />
        <div className="mobile-access">
          <section className="hero --hero-image no-pad">
            <div class="container">
              <div class="hero-block">
                <h1 dangerouslySetInnerHTML={{__html: t('mobile_access_headline')}}/>
                <h2 dangerouslySetInnerHTML={{__html: t('mobile_access_headline-sub')}} />
                <BookConsultation/>
              </div>
            </div>
          </section>
          <section className={'subhead'}>
            <div class="container">
              <h2 dangerouslySetInnerHTML={{__html: t('mobile_access_subhead')}}/>
            </div>
          </section>
          <section class="subhead-2" />
          <section class="subhead-desktop">
            <div className="container grid">
                <div>
                  <StaticImage
                      src="../../../images/mobile-access/phones.jpg"
                      loading={'lazy'}
                      width={552}
                      height={640}
                      quality={100}
                      formats={["auto", "webp", "avif"]}
                      alt="Employee ID on Phone Screen"
                  />
                </div>
                <div>
                  <h2 dangerouslySetInnerHTML={{__html: t('mobile_access_subhead')}}/>
                </div>
            </div>
          </section>
          <section className={'lists'}>
            <div class="container">
              <h2 dangerouslySetInnerHTML={{__html: t('mobile_access_mix')}} />
              <Link to={'/products/smart-locks/aspire/'}><img src={aspire} alt="Aspire" width={'207'} height={'245'}/></Link>
              <Link to={'/products/smart-locks/curve/'}><img src={curve} alt="Curve" width={'209'} height={'237'}/></Link>
              <Link to={'/products/smart-locks/versa/'}><img src={versa} alt="Versa" width={'208'} height={'236'}/></Link>
              <Link to={'/products/smart-locks/orbit/'}><img src={orbit} alt="Orbit" width={'207'} height={'159'}/></Link>
              <Link to={'/products/smart-locks/pivot/'}><img src={pivot} alt="Pivot" width={'209'} height={'237'}/></Link>
            </div>
          </section>

          <section className={'discover mobile'}>
              <div class="discover-box">
                <h2 dangerouslySetInnerHTML={{__html: t('mobile_access_discover_head')}} />
              </div>
              <div class="discover-overlayed">
                <div class="container">
                 <div class="overlayed">
                   <h3 dangerouslySetInnerHTML={{__html: t('mobile_access_ultra_conv')}} />
                   <p dangerouslySetInnerHTML={{__html: t('mobile_access_ultra_conv_copy')}} />
                   <h3 dangerouslySetInnerHTML={{__html: t('mobile_access_boost_eff')}} />
                   <p dangerouslySetInnerHTML={{__html: t('mobile_access_boost_copy')}} />
                   <h3 dangerouslySetInnerHTML={{__html: t('mobile_access_enhance_sustain')}} />
                   <p dangerouslySetInnerHTML={{__html: t('mobile_access_enhance_sustain_copy')}} />
                   <BookConsultation/>
                 </div>
                </div>
              
            </div>
          </section>
          <section className={'discover desktop'}>
            <div class="container">
              <div class="discover-box">
                <div className="discover-overlayed">
                  <div className="container">
                    <div className="overlayed">
                      <h2><Trans>mobile_access_discover_head</Trans></h2>
                      <h3 dangerouslySetInnerHTML={{__html: t('mobile_access_ultra_conv')}} />
                      <p dangerouslySetInnerHTML={{__html: t('mobile_access_ultra_conv_copy')}} />
                      <h3 dangerouslySetInnerHTML={{__html: t('mobile_access_boost_eff')}} />
                      <p dangerouslySetInnerHTML={{__html: t('mobile_access_boost_copy')}} />
                      <h3 dangerouslySetInnerHTML={{__html: t('mobile_access_enhance_sustain')}} />
                      <p dangerouslySetInnerHTML={{__html: t('mobile_access_enhance_sustain_copy')}} />
                      <BookConsultation/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className={'unlock mobile'}>
            <div className="discover-box" />
            <div className="discover-overlayed">
              <div className="container">
                <div className="overlayed">
                  <h2 dangerouslySetInnerHTML={{__html: t('mobile_access_unlock_office')}} />
                  <p dangerouslySetInnerHTML={{__html: t('mobile_access_unlock_office_copy')}} />
                </div>
              </div>
            </div>
          </section>
          <section className={'unlock desktop'}>
            <div class="container">
              <div className="discover-box">
                <div className="discover-overlayed">
                  <div className="container">
                    <div className="overlayed">
                      <h2 dangerouslySetInnerHTML={{__html: t('mobile_access_unlock_office')}} />
                      <p dangerouslySetInnerHTML={{__html: t('mobile_access_unlock_office_copy')}} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div class="container">
              <h2 dangerouslySetInnerHTML={{__html: t('mobile_access_footer')}}/>
              <BookConsultation/>
            </div>
          </section>
        </div>
      </Layout>
  )
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
